import { getKLines } from '@/binance';
import MaTable from '@/components/ma-table';
import PostTemplate from '@/templates/post';
import { DataFrame, defaultDataFrame, PostTemplateProps } from '@/types';
import { sma } from '@/utils/panda';
import { useState, useEffect } from 'react';
import { Articles } from '../../articles';
import { ChartLine } from '@/components/charts/ChartLine';
import CryptoPlot from '@/components/charts/crypto-plot';

export default function Xxx50DayMaChartTemplate({
  location,
  articleId,
  coinAbbreviation,
  coinFullName,
  token,
}: PostTemplateProps) {
  const [dataFrame, setDataFrame] = useState<DataFrame>(defaultDataFrame);
  const [ma50, setMa50] = useState<number[]>();

  const [ma50Line, setMa50Line] = useState<ChartLine>({
    y: [],
    color: 'blue',
    title: 'MA50',
  });

  useEffect(() => {
    getKLines(token, '1d', 1000).then(function (df: DataFrame) {
      setDataFrame(df);
      const sma50 = sma(df.close, 50);
      setMa50(sma50);

      setMa50Line({
        color: 'blue',
        title: 'MA50',
        y: sma50,
      });
    });
  }, [token]);

  return (
    <PostTemplate
      location={location}
      keywords={[coinFullName.toLowerCase(), 'ma50', 'chart']}
      articleId={articleId}
    >
      <section>
        <p>
          The {coinFullName} 50-day moving average (MA) chart is popular among
          crypto traders and market analysts because historical analysis of
          price movements shows it to be an effective {coinAbbreviation} trend
          indicator.
        </p>
        <CryptoPlot
          data={dataFrame}
          lines={[ma50Line]}
          title={Articles[articleId].title}
          description={Articles[articleId].description}
        />
        <p>
          The {coinAbbreviation} 50-, 100-, and 200-day moving average are
          probably among the most commonly found lines drawn on any crypto
          trader&apos;s or analyst&apos;s charts. All three are considered
          major, or significant, moving averages and represent levels of support
          or resistance in a crypto market.
        </p>
        <MaTable
          header1="Date"
          header2={'Closing Price (' + coinAbbreviation + ')'}
          header3={'50-Day Moving Average (' + coinAbbreviation + ')'}
          dates={dataFrame.date}
          close={dataFrame.close}
          ma={ma50}
          numberOfRows={10}
        />
        <h2>What Is the {coinFullName} 50-Day Moving Average Chart For?</h2>
        <p>
          The {coinAbbreviation} 50-day moving average chart is a chart that
          represents a trendline plotting of closing daily prices for the{' '}
          {coinFullName}, averaged over the past 50 days.
        </p>
        <p>
          Depending on a current {coinFullName} price action and where it
          appears relative to the 50-day simple moving average, this trendline
          can indicate a coin&apos;s strength or weakness.
        </p>
        <h2>
          Understanding the {coinAbbreviation} 50-Day Moving Average Chart
        </h2>
        <p>
          The {coinAbbreviation} 50-day moving average is the leading average of
          the three most commonly used averages. Because it is shorter than the{' '}
          {coinFullName} 100- and 200-day averages, it is the first line of
          major moving average support in an uptrend and the first line of major
          moving average resistance in a downtrend.
        </p>
        <p>
          The {coinFullName} 50-day moving average chart is popular because it
          works well as a trend indicator. The more accurate a moving average
          chart is, the more useful it is for crypto traders and analysts.
        </p>
        <p>
          The ideal moving average is a trendline that price will not likely
          violate on a mere temporary retracement (possibly giving a false
          market reversal signal if it does). It can also be used to place a
          trailing stop on an existing market position.
        </p>
        <p>
          Additionally, the the {coinFullName} 50-day moving average chart can
          be helpful because coins that approach it on retracements can signal
          additional market entry points. Through trial and error using various
          {coinAbbreviation} moving averages, the {coinFullName} 50-day moving
          average chart has served these purposes well.
        </p>
      </section>
    </PostTemplate>
  );
}
